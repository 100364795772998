import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export default () => (
  <section className="hero is-fullheight">
    <Helmet>
      <title>Iteem - page not found</title>
    </Helmet>
    <div className="hero-body">
      <div className="container content is-medium has-text-centered">
        <h1>404 Page not found</h1>
        <p>
          Looks like you've followed a broken link or entered a URL that doesn't exist on this site.
        </p>
        <p>
          <Link to="/">Back to the main page</Link>
        </p>
      </div>
    </div>
  </section>
);
